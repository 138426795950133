import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

  transform(object: any[], sortField: string): any[] {
    return object?.sort((a,b) => (a[sortField] > b[sortField] ? 1 : -1));
  }

}
