<table class="table">
    <div>
        <h3>{{ exam.name }}</h3>
        <ng-container *ngFor="let question of exam.examQuestions">
            <tr>
                <td>{{ question.questionNr }}</td>
                <td>
                    {{ question.question }}
                </td>
            </tr>
        </ng-container>
    </div>
</table>
