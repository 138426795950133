import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExamFull } from 'src/app/model/exam-full';
import { ExamQuestion } from 'src/app/model/exam-question';
import { UserAnswer } from 'src/app/model/user-answer';
import { UserExam } from 'src/app/model/user-exam';
import { ExamService } from 'src/app/service/exam-service.service';

@Component({
  selector: 'app-take-exam-flow',
  templateUrl: './take-exam-flow.component.html',
  styleUrls: ['./take-exam-flow.component.css']
})
export class TakeExamFlowComponent implements OnInit {
  examId!: number;
  exam: ExamFull = new ExamFull();
  questionNr: number = 1;
  userExam: UserExam = new UserExam();
  showAnswer: boolean = false;

  constructor(
    private examService: ExamService,
    // private examQuestionsService: ExamQuestionsService,
    private route: ActivatedRoute
  ) { }

  nrOfQuestions(): number {
    return this.exam.examQuestions.length;
  }

  getExamQuestionWithNr(nr: number): ExamQuestion {
    let examQuestion = this.exam.examQuestions.find(exam => exam.questionNr == nr);
    if (!examQuestion) {
      examQuestion = new ExamQuestion();
    }
    return examQuestion;
  }

  // getExamQuestionCurrent(): ExamQuestion {
  //   return this.getExamQuestionWithNr(this.questionNr);
  // }

  getUserAnswerWithNr(nr: number): UserAnswer {
    let answer = this.userExam.userAnswers.find(useranswer => useranswer?.questionNr == nr);
    if (!answer) {
      answer = new UserAnswer();
    }
    return answer;
  }

  getUserAnswer(): UserAnswer {
    return this.getUserAnswerWithNr(this.questionNr);
  }

  setAnswerGivenByUser(answerGiven: number[]): void {
    const answer = this.getUserAnswer();
    answer.answerGiven = answerGiven;
    answer.isAnswered = this.nrOfExpectedAnswers() == this.nrOfGivenAnswers();
    answer.isCorrect = this.isAnswerCorrect();
    this.saveExam();
  }


  ngOnInit(): void {
    this.route.paramMap.subscribe( () => this.doExam())
  }

  doExam(): void {
    const hasExamId: boolean = this.route.snapshot.paramMap.has("id");
    this.examId = +this.route.snapshot.paramMap.get("id")!;
    if (hasExamId) {
      // console.log("EXAM ID", this.examId);
      this.examService.getExamById(this.examId).subscribe(
        data => {
          this.exam = data;
          this.initUserExam(data); }
      )
      // this.examQuestionsService.getExamQuestionById()
    }
  }

  initUserExam(exam: ExamFull): void {
    const storedUserExam = localStorage.getItem(`CFexam${ this.examId }`);

    if (storedUserExam) {
      this.userExam = JSON.parse(storedUserExam);
    } else {
      // don't use array element 0 as we use questionNr for indexing
      const userAnswers = new Array<UserAnswer>(this.nrOfQuestions()+1);
      exam.examQuestions.forEach(q => {
        userAnswers[q.questionNr] = new UserAnswer();
        userAnswers[q.questionNr].questionNr = q.questionNr;
        userAnswers[q.questionNr].questionId = q.id;
      })
      this.userExam.userAnswers = userAnswers;
      this.userExam.id = this.examId;
    }
  }

  nextQuestion(): void {
    this.showAnswer = false;
    if (this.questionNr < this.nrOfQuestions()) {
      this.questionNr++;
    }
  }

  prevQuestion(): void {
    this.showAnswer = false;
    if (this.questionNr > 1) {
      this.questionNr--;
    }
  }

  // answerQuestion(): void {
  //   this.getUserAnswer().isAnswered = true;
  //   this.saveExam();
  // }

  private saveExam() {
    localStorage.setItem(`CFexam${ this.examId }`, JSON.stringify(this.userExam));
    // console.log("Saved", localStorage.getItem(`CFexam${ this.examId }`));
  }

  isAnswerValid(): boolean {
    return (this.nrOfGivenAnswers() == this.nrOfExpectedAnswers());
  }

  isAnswerCorrect(): boolean {
    const userAnswer = this.getUserAnswer();
    const examQuestion = this.getExamQuestionWithNr(this.questionNr);
    const correctAnswers = examQuestion.examAnswers?.filter(answer => answer.correct).map(answer => answer.orderNr) || [];
    return userAnswer.answerGiven.length === correctAnswers.length &&
      userAnswer.answerGiven.every(answer => correctAnswers.includes(answer));
  }

  nrOfExpectedAnswers(): number {
    return this.getExamQuestionWithNr(this.questionNr).examAnswers?.filter(answer => answer.correct).length || 0;
  }

  nrOfGivenAnswers(): number {
    return this.getUserAnswer().answerGiven.length;
  }


}
