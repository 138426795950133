import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ViewAndAnswerAQuestionComponent } from './components/take-exam/view-and-answer-a-question/view-and-answer-a-question.component';
import { HttpClientModule } from '@angular/common/http';
import { ViewExamQuestionsComponent } from './components/view-exam-questions/view-exam-questions.component';
import { ExamOverviewComponent } from './components/exam-overview/exam-overview.component';
import { AppRoutingModule } from './app-routing.module';
import { TakeExamFlowComponent } from './components/take-exam/take-exam-flow/take-exam.component';
import { SortByPipe } from './pipes/sort-by.pipe';


@NgModule({
  declarations: [
    AppComponent,
    ViewAndAnswerAQuestionComponent,
    ViewExamQuestionsComponent,
    ExamOverviewComponent,
    TakeExamFlowComponent,
    SortByPipe
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
