import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserAnswer } from 'src/app/model/user-answer';
import { ExamQuestion } from 'src/app/model/exam-question';
import { ExamQuestionsService } from 'src/app/service/exam-questions.service';
import { ExamAnswer } from 'src/app/model/exam-answer';

@Component({
  selector: 'app-view-question',
  templateUrl: './view-and-answer-a-question.component.html',
  styleUrls: ['./view-and-answer-a-question.component.css'],
})
export class ViewAndAnswerAQuestionComponent implements OnInit {
  // @Input()
  // get questionNr(): number { return this._questionNr; }
  // set questionNr(nr: number) {
  //   this._questionNr = nr;
  //   this.getExamQuestionById(nr);
  // }
  // private _questionNr = 1;

  @Input()
  get examQuestion(): ExamQuestion {
    return this._examQuestion;
  }
  set examQuestion(examQuestion: ExamQuestion) {
    this._examQuestion = examQuestion;
  }
  private _examQuestion!: ExamQuestion;

  // @Input()
  // get isAnswered(): boolean { return this._isAnswered; }
  // set isAnswered(isAnswered: boolean) {
  //   this._isAnswered = isAnswered;
  // }
  // private _isAnswered: boolean = false;

  @Input()
  get userAnswer(): UserAnswer {
    return this._userAnswer;
  }
  set userAnswer(answer: UserAnswer) {
    this.answer = answer.answerGiven;
    this._userAnswer = answer;
  }
  private _userAnswer: UserAnswer = new UserAnswer();

  @Input()
  showAnswer: boolean = false;

  @Input()
  isSubmitted: boolean = false;

  @Output() userAnswerEvent = new EventEmitter<number[]>();

  answer: number[] = [];

  constructor(private examQuestionsService: ExamQuestionsService) {}

  ngOnInit(): void {
    // this.getAllExamQuestions();
    // this.getExamQuestionById();
  }

  expectedNrOfAnswers(): number {
    if (!this.examQuestion.examAnswers) {
      return 0;
    }
    return this.examQuestion.examAnswers.filter(
      (examanswer) => examanswer.correct
    ).length;
  }

  getButtonType(): ButtonType {
    return this.expectedNrOfAnswers() > 1
      ? ButtonType.Checkbox
      : ButtonType.Radio;
  }

  // answerTheQuestion(): void {
  //   let answers = [1];
  //   this.userAnswerEvent.emit(answers);
  // }

  giveAnswer(newAnswer: ExamAnswer): void {
    if (this.getButtonType() == ButtonType.Radio) {
      this.answer = [newAnswer.orderNr];
    } else {
      if (this.answer.some((answer) => answer == newAnswer.orderNr)) {
        this.answer = this.answer.filter(
          (answer) => answer != newAnswer.orderNr
        );
      } else {
        this.answer.push(newAnswer.orderNr);
      }
    }
    this.answer = this.answer.map((nr) => Number(nr));
    this.userAnswerEvent.emit(this.answer);
  }

  isAnswerChecked(answerNr: number): boolean {
    // console.log("ANSWER", answerNr);
    // let x = this.userAnswer.answerGiven.includes(answerNr);
    let x = this.userAnswer.answerGiven.filter((a) => a == answerNr).length > 0;
    return x;
  }

  toMultipleChoiceLetter(answerNr: number): string {
    return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[answerNr - 1];
  }

  // examQuestions!: ExamQuestion[];
  // examQuestion!: ExamQuestion;
  // answers: ExamAnswer[] = this.examQuestion.examAnswers;

  // getAllExamQuestions() {
  //   this.examQuestionsService.getAllLessonsByPage().subscribe(
  //     data => this.examQuestions = data
  //   )
  // }

  // getExamQuestionById(id: number) {
  //   this.examQuestionsService.getExamQuestionById(id).subscribe(
  //     data => this.examQuestion = data
  //   )
  //   console.log("XXXXXXXXXXX", this.examQuestion);
  //   console.log("XXXXXXXXXXX", this.questionNr);
  // }
}

enum ButtonType {
  Radio = "radio",
  Checkbox = "checkbox"
}
