import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ExamQuestion } from '../model/exam-question';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamQuestionsService {

  private baseUrl = environment.apiUrl;
  private examQuestionUrl = `${this.baseUrl}/examQuestions`;

  constructor(private httpClient: HttpClient) { }


  getAllLessonsByPage() : Observable<ExamQuestion[]> {
    const url = `${this.examQuestionUrl}/all`;
    return this.httpClient.get<any>(url).pipe(
      map((response: { content: any; }) => response.content)
    );
  }

  getExamQuestionById(id: number): Observable<ExamQuestion> {
    const url = `${this.examQuestionUrl}/id/${id}`;
    return this.httpClient.get<ExamQuestion>(url);
  }

}
