import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExamOverviewComponent } from './components/exam-overview/exam-overview.component';
import { TakeExamFlowComponent } from './components/take-exam/take-exam-flow/take-exam.component';
import { ViewExamQuestionsComponent } from './components/view-exam-questions/view-exam-questions.component';

const routes: Routes = [
  { path: 'examOverview', component: ExamOverviewComponent, data: {title: 'Examens'} },
  { path: 'takeExam/:id', component: TakeExamFlowComponent, data: {title: 'Maak examen'} },
  { path: 'examQuestions/:id', component: ViewExamQuestionsComponent, data: {title: 'Examenvragen'} },
  { path: '', redirectTo: 'examOverview', pathMatch: 'full' },
  { path: '**', redirectTo: '' }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]  
})
export class AppRoutingModule { }
