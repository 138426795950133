import { ExamAnswer } from "./exam-answer";

export class ExamQuestion {
    id!: number;
    category?: string;
    explanation?: string;
    question?: string;
    questionNr!: number;
    examId?: number;
    examAnswers! : ExamAnswer[];
}
