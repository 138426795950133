<h2>Proefexamens</h2>
<table class="table table-striped">
    <thead class="table-dark">
        <tr>
            <th scope="col">Examen</th>
            <th scope="col">Progressie</th>
            <th scope="col"></th>
            <th scope="col"></th>
        </tr>
    </thead>

    <tbody class="table-group-divider">
        <ng-container *ngFor="let exam of exams">
            <tr>

                <td> <span routerLink="/examQuestions/{{ exam.id }}">
                        {{ exam.name }}
                    </span>
                    <!-- </td>
                <span routerLink="/examQuestions/{{ exam.id }}" >
                    {{ exam.id }}
                </span>
            <td> -->

                </td>
                <td>
                    <span [innerHtml]="getProgressStatus(exam.id)"></span>
                </td>
                <td>
                    <button type="button" class="btn btn-primary" (click)="takeExam(exam.id)">
                        {{ getProgressStatus(exam.id) == "-" ? "Start examen"
                           : getStoredUserExam(exam.id)?.isSubmitted
                             ? "Toon antwoorden" : "Hervat examen" }}
                    </button>
                </td>
                <!-- <td>
                    <button type="button" class="btn btn-primary" (click)="submitExam(exam.id)" [disabled]="getStoredUserExam(exam.id)?.isSubmitted">
                      Inleveren
                    </button>
                </td> -->
                <td>
                  <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                      <i class="bi bi-three-dots-vertical"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" [ngClass]="{'disabled': getStoredUserExam(exam.id)?.isSubmitted || !isExamStarted(exam.id)}"
                          (click)="submitExam(exam.id)">
                          Inleveren
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" [ngClass]="{'disabled': !isExamStarted(exam.id)}"
                          (click)="deleteAnswers(exam.id)">
                          Verwijder antwoorden</a>
                      </li>
                      <li>
                        <a class="dropdown-item" (click)="showExamQuestion(exam.id)">Overzicht vragen</a>
                      </li>
                    </ul>

                    <!-- <button type="button" class="btn btn-primary" (click)="deleteAnswers(exam.id)">
                      Verwijder antwoorden
                    </button> -->
                    <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#deleteAnswersModal" data-bs-exam-id="exam.id">
                        Verwijder antwoorden
                    </button> -->
                  </div>
                </td>

            </tr>
        </ng-container>
    </tbody>
</table>

<!-- Modal delete answers -->

  <!-- Modal -->
  <div class="modal fade" id="deleteAnswersModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Alle antwoorden voor dit examen worden verwijderd.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Nee</button>
          <button type="button" class="btn btn-primary">Verwijder antwoorden</button>
        </div>
      </div>
    </div>
  </div>
