import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Exam } from '../model/exam';
import { ExamFull } from '../model/exam-full';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamService {

  private baseUrl = environment.apiUrl;
  private examPath = `${this.baseUrl}/exams`;

  constructor(private httpClient: HttpClient) { }


  public getAllExamTitles() : Observable<Exam[]> {
    const url = `${this.examPath}/titles`;
    return this.httpClient.get<Exam[]>(url);
  }

  public getExamById(examId: number): Observable<ExamFull> {
    const url = `${this.examPath}/id/${examId}`;
    return this.httpClient.get<ExamFull>(url);
  }
}
