import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Exam } from 'src/app/model/exam';
import { UserAnswer } from 'src/app/model/user-answer';
import { UserExam } from 'src/app/model/user-exam';
import { ExamService } from 'src/app/service/exam-service.service';

@Component({
  selector: 'app-exam-overview',
  templateUrl: './exam-overview.component.html',
  styleUrls: ['./exam-overview.component.css']
})
export class ExamOverviewComponent implements OnInit {

  exams!: Exam[];

  constructor(
    private router: Router,
    private examService: ExamService) { }

  ngOnInit(): void {
    this.getAllExamTitles();
    // this.installModalListener();
  }

  // installModalListener() {
  //   const exampleModal = document.getElementById('deleteAnswersModal')
  //   if (exampleModal) {
  //     exampleModal.addEventListener('Exam', event => {
  //       // Button that triggered the modal
  //       const button = event.id
  //       // Extract info from data-bs-* attributes
  //       const examId = button.getAttribute('data-bs-exam-id')
  //       // If necessary, you could initiate an Ajax request here
  //       // and then do the updating in a callback.

  //       // Update the modal's content.
  //       const modalTitle = exampleModal.querySelector('.modal-title')
  //       const modalBodyInput = exampleModal.querySelector('.modal-body input')

  //       modalTitle.textContent = `New message to ${recipient}`
  //       modalBodyInput.value = examId
  //     })
  //   }
  // }

  getAllExamTitles(): void {
    this.examService.getAllExamTitles().subscribe(
      data => { this.exams = data; }
    )
  }

  getStoredUserExam(examId: number): UserExam | null {
    const storedUserExam: string | null = localStorage.getItem(`CFexam${examId}`);

    if (storedUserExam) {
      return JSON.parse(storedUserExam) as UserExam;
    } else {
      return null;
    }
  }

  private saveExam(examId: number, userExam: UserExam): void {
    localStorage.setItem(`CFexam${ examId }`, JSON.stringify(userExam));
  }

  showExamQuestion(id: number): void {
    this.router.navigate([`/examQuestions/${id}`]);
  }

  takeExam(id: number) {
    this.router.navigate([`/takeExam/${id}`]);
  }

  submitExam(examId: number) {
    const userExam = this.getStoredUserExam(examId);

    if (userExam) {
      userExam.isSubmitted = true;
      // userExam.finalScorePercentage = this.computeScoreAsPrecentage(examId);
      this.saveExam(examId, userExam);
    }
  }

  deleteAnswers(id: number) {
    localStorage.removeItem(`CFexam${id}`);
  }

  getProgressStatus(examId: number): string {
    const userExam = this.getStoredUserExam(examId);

    if (userExam) {
      const userAnswers: UserAnswer[] = userExam.userAnswers;
      if (userExam.isSubmitted) {
        return `Voltooid | Score: ${ this.nrOfCorrectAnswers(userExam) }/${userAnswers.length - 1} (${this.computeScoreAsPrecentage(examId)}%)`;
      } else {
        const answered = userAnswers.filter(answer => this.isAnswerValid(answer));
        return `Gestart | Beantwoord: ${answered.length}/${userAnswers.length - 1}`;
      }
    } else {
      return "-";
    }
  }

  isExamStarted(examId: number): boolean {
    return this.getProgressStatus(examId) !== "-";
  }

  isAnswerValid(answer: UserAnswer): boolean {
    return this.nrOfGivenAnswers(answer) > 0;
  }

  nrOfGivenAnswers(answer: UserAnswer): number {
    return answer?.answerGiven.length;
  }

  nrOfCorrectAnswers(userExam: UserExam): number {
    return userExam.userAnswers.filter(answer => answer?.isCorrect).length;
  }

  computeScoreAsPrecentage(examId: number): string {
    const userExam = this.getStoredUserExam(examId);
    if (userExam) {
      const userAnswers = userExam.userAnswers;
      return "" + (this.nrOfCorrectAnswers(userExam) / --userAnswers.length * 100).toFixed(0);
    }
    return "-";
  }
}
