<div class="container-fluid">
  <h1>{{ exam.name }}</h1>
  <hr>
  <div class="row mb-2 fs-4 fw-bold">
    <div class="col-2">
      Vraag {{ questionNr }} / {{ nrOfQuestions() }}
    </div>
    <div class="col-10">
      <span [hidden]="!userExam.isSubmitted">
        <span class="rounded px-5"
          [ngClass]="getUserAnswer().isCorrect ? 'text-bg-success': 'text-bg-danger'">
            {{ getUserAnswer().isCorrect ? 'Goed' : 'Fout' }}
        </span>
      </span>
    </div>
  </div>


  <div class="row">
    <!-- <div class="controlRow"> -->
    <div class="col-9 d-grid gap-3 d-md-flex justify-content-md-left">
      <button type="button" class="btn btn-primary" (click)="prevQuestion()"
        [disabled]="questionNr == 1 || (nrOfGivenAnswers() > 0 && (nrOfGivenAnswers() != nrOfExpectedAnswers()))">
        <i class="bi-arrow-left px-5"></i>
      </button>
      <!-- </div> -->

      <!-- <div class="col-2"> -->
      <button type="button" class="btn btn-primary" (click)="nextQuestion()"
        [disabled]="questionNr == nrOfQuestions() || (nrOfGivenAnswers() > 0 && (nrOfGivenAnswers() != nrOfExpectedAnswers()))">
        <i class="bi-arrow-right px-5"></i>
      </button>

      <!-- <div class="col-2"> -->
      <span class="px-1">
      </span>

      <button type="button" class="btn btn-primary px-5"
        (click)="showAnswer = !showAnswer"
        [innerHTML]="showAnswer ? 'Verberg Antwoord' : 'Toon Antwoord'"
        [disabled]="!isAnswerValid()"
        [hidden]="userExam.isSubmitted"
        >
        Toon Antwoord
        <!-- <i class="bi-arrow-right" style="font-size: 2rem; color: rgb(230, 235, 245);"></i> -->
      </button>
      <!-- </div> -->
    </div>
<!--
    <div class="col-1"> -->
      <!-- <button type="button" class="btn btn-primary"> -->
      <!-- <i class="bi-arrow-right" style="font-size: 2rem; color: rgb(230, 235, 245);"></i> -->
      <!-- </button> -->
    <!-- </div> -->

    <div class="col-3">
      <div class="d-grid gap-5 d-md-flex justify-content-md-end">
        <button type="button" class="btn btn-primary"
          [disabled]="(nrOfGivenAnswers() > 0 ) && !isAnswerValid()"
          [routerLink]="'/examOverview'">
          Stop & Bewaar
          <!-- <i class="bi-arrow-right" style="font-size: 2rem; color: rgb(230, 235, 245);"></i> -->
        </button>
      </div>
    </div>
    <!-- </div> -->
  </div>
  <hr>

  <div class="row">
    <app-view-question
      [examQuestion]="getExamQuestionWithNr(questionNr)"
      [userAnswer]="getUserAnswer()"
      [showAnswer]="showAnswer"
      [isSubmitted]="userExam.isSubmitted"
      (userAnswerEvent)="setAnswerGivenByUser($event)">
    </app-view-question>
  </div>
</div>
