import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExamFull } from 'src/app/model/exam-full';
import { ExamService } from 'src/app/service/exam-service.service';

@Component({
  selector: 'app-view-exam-questions',
  templateUrl: './view-exam-questions.component.html',
  styleUrls: ['./view-exam-questions.component.css']
})
export class ViewExamQuestionsComponent implements OnInit {
  examId!: number;
  exam: ExamFull = new ExamFull();

  constructor(
    private examService: ExamService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(() =>
      this.listExamQuestions()
    )
  }

  listExamQuestions(): void {
    const hasExamId: boolean = this.route.snapshot.paramMap.has("id");
    if (hasExamId) {
      this.examId = +this.route.snapshot.paramMap.get("id")!;
      console.log("EXAM ID", this.examId);
      this.examService.getExamById(this.examId).subscribe(
        data => { this.exam = data }
      )
    }
  }

}
