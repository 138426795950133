// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from "./ienvironment";
// import { environment } from "src/assets/environment.js";

declare const externalEnvironment: Environment;
export var environment: Environment;

function waitUntilSet(waitMilliSec: number): void {
  try {
    if (externalEnvironment) {
      console.log("ENVIRONMENT", externalEnvironment);
      environment = externalEnvironment;
    }
  } catch (error) {
    console.log("Waiting for external environment to be set", waitMilliSec);
    setTimeout(waitUntilSet, waitMilliSec, waitMilliSec*2);
  }
}

waitUntilSet(250);


// export const env: any = globalThis.externalEnvironment;


// let fileEnv = new File([], 'dist/assets/environment.js');
// readFile(fileEnv);
// function readFile(file: File) {
//   var reader = new FileReader();
//   reader.onload = () => {
//       console.log(reader.result);
//   };
//   reader.readAsText(file);
//   console.log('RRRRRRRRRRRRRRRrrr', reader.result);
// }

// export default environment;

// export const environment: Environment = {
//   production: false,
//   apiUrl: "http://localhost:8080"
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
