<h4>Vraagstelling</h4>
<span class="question_formatting" [innerHTML]="examQuestion.question"></span>
<br><br>
<h4>Antwoorden</h4>
<div *ngIf="expectedNrOfAnswers() > 1">
    Geef {{ expectedNrOfAnswers() }} antwoorden:
</div>
<table class="table my-2 table-secondary">
    <tbody>
        <ng-container *ngFor="let answer of examQuestion.examAnswers | sortBy:'orderNr'">
            <tr
              (click)="giveAnswer(answer)"
              [style.pointer-events]="isSubmitted ? 'none' : 'auto'"
            >
                <td class="small-column">
                    <input class="form-check-input"
                      [type]="getButtonType()"
                      [checked]="isAnswerChecked(answer.orderNr)"
                      [disabled]="isSubmitted"
                      name="question"
                      >
                </td>
                <th class="small-column">{{ toMultipleChoiceLetter(answer.orderNr) }}.</th>
                <td class="answer-formatting"
                  [ngClass]="
                    !showAnswer && !isSubmitted
                      ? ''
                      : answer.correct ? 'table-success' : 'table-danger'">

                    <!-- <b>{{ answer.orderNr }}.</b>&nbsp; -->
                    <span [innerHTML]="answer.answer"></span><br>
                    <ng-container *ngIf="answer.explanation">
                        Uitleg: <span [innerHTML]="answer.explanation"></span>
                    </ng-container>
                </td>
            </tr>
            <!-- <tr *ngIf="userAnswer.isAnswered">
            <td></td>
            <td></td>
            <td class="bg-body-secondary">
                <span [innerHTML]="answer.explanation"></span>
            </td>
        </tr> -->
        </ng-container>
    </tbody>
</table>
<hr>
<div *ngIf="showAnswer || isSubmitted">
    <h4>Toelichting</h4>
    <br>
    <span *ngIf="examQuestion.explanation; else noExplanation">
        <span [innerHTML]="examQuestion.explanation"></span>
    </span>
    <ng-template #noExplanation>
        -
    </ng-template>
</div>
<!-- <ng-container *ngFor="let x of examQuestions">
        <b>{{ x.id }}</b>
        <div [innerHtml]="x.question"></div>
        <br>
    </ng-container> -->
